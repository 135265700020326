import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {mapResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResponseEntity'
import {mapResponseResourcePath} from '@toolify/client/src/modules/api/singletons/mapResponseResourcePath'

export function mapNotificationResponseEntity(notification: IToJson<INotificationResponseEntity<NotificationType>>): INotificationResponseEntity<NotificationType> {
  return {
    ...notification,
    resourcePath: mapResponseResourcePath(notification.resourcePath),
    authorEntity: mapResponseEntity(notification.authorEntity),
    createdAt: notification.createdAt ? new Date(notification.createdAt) : null,
    readAt: notification.readAt ? new Date(notification.readAt) : null,
    seenAt: notification.seenAt ? new Date(notification.seenAt) : null,
  }
}
