import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  IGetNotificationsRequestInterfaces,
} from '@toolify/server/src/controllers/NotificationController/validators/GetNotifications/types/IGetNotificationsRequestInterfaces'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {mapNotificationResponseEntity} from '@toolify/client/src/modules/api/singletons/mapNotificationResponseEntity'
import {
  IGetUnseenNotificationsCountRequestInterfaces,
} from '@toolify/server/src/controllers/NotificationController/validators/GetUnseenNotificationsCount/types/IGetUnseenNotificationsCountRequestInterfaces'
import {
  ISetAllUserNotificationsSeenRequestInterfaces,
} from '@toolify/server/src/controllers/NotificationController/validators/SetAllUserNotificationsSeen/types/ISetAllUserNotificationsSeenRequestInterfaces'
import {
  IUpdateNotificationRequestInterfaces,
} from '@toolify/server/src/controllers/NotificationController/validators/UpdateNotification/types/IUpdateNotificationRequestInterfaces'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {
  NotificationFilterType,
} from '@toolify/server/src/controllers/NotificationController/enum/NotificationFilterType'
import {
  IGetUnreadNotificationTypesCountRequestInterfaces,
} from '@toolify/server/src/controllers/NotificationController/validators/GetUnreadNotificationTypesCount/types/IGetUnreadNotificationTypesCountRequestInterfaces'
export const useNotificationApiStore = defineStore('notificationApi', {
  actions: {
    async getNotifications(
      notificationFilterType = NotificationFilterType.All,
      offset = 0,
      limit = 30,
      resourceType?: ResourceType,
      resourceId?: string,
    ): Promise<INotificationResponseEntity<NotificationType>[]> {
      const apiRequestStore = useApiRequestStore()
      const url = (resourceType && resourceId) ? `/resource/${resourceType}/${resourceId}/notifications` : '/notifications'
      const {notifications} = await apiRequestStore.get<IGetNotificationsRequestInterfaces>(url, {
        params: {
          notificationFilterType,
          offset,
          limit,
        },
      })
      return notifications.map(notification => {
        return mapNotificationResponseEntity(notification)
      })
    },

    async getUnseenNotificationsCount(
      resourceType?: ResourceType,
      resourceId?: string,
    ): Promise<number> {
      const apiRequestStore = useApiRequestStore()
      const url = (resourceType && resourceId) ? `/resource/${resourceType}/${resourceId}/notifications/count` : '/notifications/count'
      const {count} = await apiRequestStore.get<IGetUnseenNotificationsCountRequestInterfaces>(url)
      return count
    },
    async getUnreadNotificationTypesCount(
      resourceType?: ResourceType,
      resourceId?: string,
    ): Promise<IGetUnreadNotificationTypesCountRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const url = (resourceType && resourceId) ? `/resource/${resourceType}/${resourceId}/notifications/unread/count` : '/notifications/unread/count'
      const response = await apiRequestStore.get<IGetUnreadNotificationTypesCountRequestInterfaces>(url)
      return response
    },

    async setAllUserNotificationsSeen(
      resourceType?: ResourceType,
      resourceId?: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      const url = (resourceType && resourceId) ? `/resource/${resourceType}/${resourceId}/notifications/seen` : '/notifications/seen'
      await apiRequestStore.post<ISetAllUserNotificationsSeenRequestInterfaces>(url)
    },

    async setAllUserNotificationsRead(
      notificationFilterType = NotificationFilterType.All,
      resourceType?: ResourceType,
      resourceId?: string,
    ): Promise<number> {
      const apiRequestStore = useApiRequestStore()
      const url = (resourceType && resourceId) ? `/resource/${resourceType}/${resourceId}/notifications/read` : '/notifications/read'
      const {readNotificationsCount} = await apiRequestStore.post<ISetAllUserNotificationsSeenRequestInterfaces>(url, {
        data: {
          notificationFilterType,
        },
      })
      return readNotificationsCount
    },

    async markNotificationAsRead(notificationId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.patch<IUpdateNotificationRequestInterfaces>(`/notifications/${notificationId}`, {
        data: {
          readAt: new Date(),
        },
      })
    },
    async markNotificationAsUnread(notificationId: string): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.patch<IUpdateNotificationRequestInterfaces>(`/notifications/${notificationId}`, {
        data: {
          readAt: null,
        },
      })
    },
  },
})
