export enum PropertyStrategyThemeType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Outlined = 'outlined',
  Transparent = 'transparent',
  Search = 'search',
  Checklist = 'checklist',
  Light = 'light',
  SidebarSearch = 'sidebar-search',
}
