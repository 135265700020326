export enum IconNameType {
    Plus = 'plus',
    H = 'h',
    AlignLeft = 'align-left',
    ChevronUp = 'chevron-up',
    ChevronDown = 'chevron-down',
    Trash = 'trash',
    Eye = 'eye',
    EyeSlash = 'eye-slash',
    Ellipsis = 'ellipsis',
    FolderOpen = 'folder-open',
    FolderClosed = 'folder-closed',
    House = 'house',
    Clock = 'clock',
    Pen = 'pen',
    CaretRight = 'caret-right',
    CaretDown = 'caret-down',
    CaretUp = 'caret-up',
    CalendarDays = 'calendar-days',
    UserPlus = 'user-plus',
    User = 'user',
    UserSecret = 'user-secret',
    Gear = 'gear',
    ClockRotateLeft = 'clock-rotate-left',
    Clone = 'clone',
    Compress = 'compress',
    BarsFilter = 'bars-filter',
    Lock = 'lock',
    Link = 'link',
    FileImport = 'file-import',
    FileExport = 'file-export',
    Sort = 'sort',
    Filter = 'filter',
    ArrowLeft = 'arrow-left',
    ArrowRight = 'arrow-right',
    LayerGroup = 'layer-group',
    List = 'list',
    Ban = 'ban',
    Thumbtack = 'thumbtack',
    EarthAmericas = 'earth-americas',
    FloppyDisk = 'floppy-disk',
    Gears = 'gears',
    Copy = 'copy',
    Table = 'table',
    LocationDot = 'location-dot',
    AlignJustify = 'align-justify',
    Calendar = 'calendar',
    Columns3 = 'columns-3',
    Anchor = 'anchor',
    SignalStream = 'signal-stream',
    ChevronRight = 'chevron-right',
    ChevronLeft = 'chevron-left',
    PizzaSlice = 'pizza-slice',
    EllipsisVertical = 'ellipsis-vertical',
    LeftToBracket = 'left-to-bracket',
    Upload = 'upload',
    ArrowDownToBracket = 'arrow-down-to-bracket',
    UpToBracket = 'up-to-bracket',
    PaperPlane = 'paper-plane',
    CirclePlus = 'circle-plus',
    PenLine = 'pen-line',
    ArrowUpArrowDown = 'arrow-up-arrow-down',
    RightToBracket = 'right-to-bracket',
    RotateLeft = 'rotate-left',
    RotateRight = 'rotate-right',
    MagnifyingGlassMinus = 'magnifying-glass-minus',
    MagnifyingGlassPlus = 'magnifying-glass-plus',
    Maximize = 'maximize',
    Download = 'download',
    Database = 'database',
    CommentLines = 'comment-lines',
    Microphone = 'microphone',
    Grid2 = 'grid-2',
    Bolt = 'bolt',
    Folder = 'folder',
    SquareCheck = 'square-check',
    BarsSort = 'bars-sort',
    Sidebar = 'sidebar',
    Text = 'text',
    GripVertical = 'grip-vertical',
    SortNumericDown = 'sort-numeric-down',
    ClipboardCheck = 'clipboard-check',
    Feather = 'feather',
    UserVneck = 'user-vneck',
    Star = 'star',
    CommentMiddle = 'comment-middle',
    File = 'file',
    Tags = 'tags',
    Phone = 'phone',
    At = 'at',
    MapMarkerAlt = 'map-marker-alt',
    ArrowUpRightFromSquare = 'arrow-up-right-from-square',
    LinkSimple = 'link-simple',
    WandMagicSparkles = 'wand-magic-sparkles',
    Calculator = 'calculator',
    Stopwatch = 'stopwatch',
    FaCircle1 = 'fa-circle-1',
    Xmark = 'xmark',
    Robot = 'robot',
    FileImage = 'file-image',
    Shield = 'shield',
    FileAudio = 'file-audio',
    FileVideo = 'file-video',
    FilePdf = 'file-pdf',
    FileSpreadsheet = 'file-spreadsheet',
    ChevronsLeft = 'chevrons-left',
    ChevronsRight = 'chevrons-right',
    XmarkLarge = 'xmark-large',
    Stream = 'stream',
    Envelope = 'envelope',
    AlarmSnooze = 'alarm-snooze',
    DiagramSubtask ='diagram-subtask',
    Search = 'search',
    Spinner = 'spinner',
    Stop = 'stop',
    PhoneXmark = 'phone-xmark',
    Reply = 'reply',
    PowerOff = 'power-off',
    AngleLeft = 'angle-left',
    LocationArrow = 'location-arrow',
    Expand = 'expand',
    CircleNodes = 'circle-nodes',
    Code = 'code',
    RectangleVerticalHistory = 'rectangle-vertical-history',
    Play = 'play',
    Comment = 'comment',
    FileUpload = 'file-upload',
    FaceSmile = 'face-smile',
    Edit = 'edit',
    Windows = 'windows',
    Linux = 'linux',
    Apple = 'apple',
    Save = 'save',
    Pause = 'pause',
    Times = 'times',
    Check = 'check',
    GooglePlay = 'google-play',
    Camera = 'camera',
    CameraSlash = 'camera-slash',
    MicrophoneSlash = 'microphone-slash',
    Desktop = 'desktop',
    DesktopSlash = 'desktop-slash',
    Droplet = 'droplet',
    DropletSlash = 'droplet-slash',
    Up = 'up',
    Left = 'left',
    Undo = 'undo',
    UserFriends = 'user-friends',
    AnglesLeft = 'angles-left',
    Bell = 'bell',
    InfoCircle = 'info-circle',
    Refresh = 'refresh',
    ArrowDownToLine = 'arrow-down-to-line',
    SquareTerminal = 'square-terminal',
    Minus = 'minus',
    VolumeSlash = 'volume-slash',
    VolumeUp = 'volume-up',
    Volume = 'volume',
    VolumeDown = 'volume-down',
    Redo = 'redo',
    RotateReverse = 'rotate-reverse',
    Crown = 'crown',
}
