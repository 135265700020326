export enum KnownError {
  NotFound = 'not-found',
  InternalServerError = 'internal-server-error',
  UserAlreadyExists = 'user-already-exists',
  UserNotVerified = 'user-not-verified',
  InvalidCredentials = 'invalid-credentials',
  CommonPassword = 'common-password',
  INVALID_MAIL = 'INVALID_MAIL',
  INVALID_REQUEST = 'INVALID_REQUEST',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  INVALID_TOKEN = 'INVALID_TOKEN',
  VERIFICATION_INVALID = 'VERIFICATION_INVALID',
  NOT_ALLOWED = 'NOT_ALLOWED',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  INVALID_FIELD_VALUE = 'INVALID_FIELD_VALUE',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  FORBIDDEN = 'FORBIDDEN',
  WorkspaceAlreadyExists = 'workspaceAlreadyExists',
  NoPermissions = 'noPermissions',
  RemoveMembersFirst = 'removeMembersFirst',
  NoRemoveAdmin = 'noRemoveAdmin',
  InvalidSettingName = 'InvalidSettingName',
  TwoFactorRequired = 'twoFactorRequired',
  TwoFactorAlreadyEnabled = 'twoFactorAlreadyEnabled',
  InvalidTwoFactorCode = 'InvalidTwoFactorCode',
  PropertyTypeMismatch = 'propertyTypeMismatch',
  PropertyIsMissingForeignPropertyId = 'propertyIsMissingForeignPropertyId',
  RequestingEntityInvalid = 'requestingEntityInvalid',
  INVALID_CAPTCHA = 'INVALID_CAPTCHA',
  AutomationVersionStatusInvalid = 'automationVersionStatusInvalid',
  TestException = 'testException',
}
