<script setup lang="ts">
import {IconNameType} from '@toolify/client/src/modules/ui/enum/IconNameType'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {IconWeightType} from '../enum/IconWeightType'
import {IconWeightTypeToClassNameMap} from '../maps/IconWeightTypeToClassNameMap'
import {StyleVariantType} from '@toolify/client/src/modules/ui/enum/StyleVariantType'
import {ColorVariantType} from '@toolify/client/src/modules/ui/enum/ColorVariantType'
import {SizeVariantType} from '@toolify/client/src/modules/ui/enum/SizeVariantType'
import {IconBehavior} from '../enum/IconBehavior'

interface Props {
  name: IconNameType
  behavior?: IconBehavior
  weightType?: IconWeightType
  styleVariant?: StyleVariantType
  colorVariant?: ColorVariantType
  size?: SizeVariantType
}

const props = withDefaults(defineProps<Props>(), {
  weightType: IconWeightType.Regular,
  styleVariant: StyleVariantType.ClearFilled,
  colorVariant: ColorVariantType.Neutral,
  size: SizeVariantType.Standard,
  behavior: IconBehavior.None,
})
</script>

<template>
  <div
    class="icon-button"
    :class="[`theme-${props.colorVariant}`, `style-${props.styleVariant}`, `wrapper-size-${props.size}`]"
  >
    <FontAwesomeIcon
      :icon="[IconWeightTypeToClassNameMap.get(props.weightType), props.name]"
      :spin="props.behavior === IconBehavior.Spin"
      class="icon"
    />
  </div>
</template>

<style scoped lang="scss">
@use '../../../styles/variables' as variables;

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;

  &.wrapper-size-mega{
    font-size: map-get(variables.$iconSizes, mega);
    width: map-get(variables.$componentSizes, mega);
    height: map-get(variables.$componentSizes, mega);
    border-radius: map-get(variables.$componentRadius, mega);
  }

  &.wrapper-size-maxi{
    font-size: map-get(variables.$iconSizes, maxi);
    width: map-get(variables.$componentSizes, maxi);
    height: map-get(variables.$componentSizes, maxi);
    border-radius: map-get(variables.$componentRadius, maxi);
  }

  &.wrapper-size-standard{
    font-size: map-get(variables.$iconSizes, standard);
    width: map-get(variables.$componentSizes, standard);
    height: map-get(variables.$componentSizes, standard);
    border-radius: map-get(variables.$componentRadius, standard);
  }

  &.wrapper-size-compact{
    font-size: map-get(variables.$iconSizes, compact);
    width: map-get(variables.$componentSizes, compact);
    height: map-get(variables.$componentSizes, compact);
    border-radius: map-get(variables.$componentRadius, compact);
  }

  &:hover{
    cursor: pointer;
  }
}

.theme-error{
  color: map-get(variables.$colors, error);

  &:hover{
    color: map-get(variables.$colors, on-error-container);
  }

  &.style-clear-filled:hover{
    color: map-get(variables.$colors, error);
    background-color: map-get(variables.$colors, container-high);
  }
}

.theme-neutral{
  color: map-get(variables.$colors, content-dim);

  &:hover{
    color: map-get(variables.$colors, content-normal);
  }

  &.style-clear-filled:hover{
    background-color: map-get(variables.$colors, container-high);
  }

  &.style-filled-filled{
    color: map-get(variables.$colors, content-normal);
    background: map-get(variables.$colors, container-high);

    &:hover{
      background: map-get(variables.$colors, container-highest);
    }
  }
}

.theme-primary{
  color: map-get(variables.$colors, on-primary);

  &.style-clear-filled:hover{
    color: map-get(variables.$colors, on-primary);
    background: map-get(variables.$colors, primary);
  }

  &.style-filled-filled{
    color: map-get(variables.$colors, on-primary);
    background: map-get(variables.$colors, primary);

    &:hover{
      background: map-get(variables.$colors, primary-hover);
    }
  }
}

.theme-dark{
  color: map-get(variables.$colors, content-dark);

  &:hover{
    color: map-get(variables.$colors, content-dim);
  }

  &.style-clear-filled:hover{
    color: map-get(variables.$colors, content-normal);
    background: map-get(variables.$colors, container-high);
  }
}
</style>
