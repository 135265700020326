import {createApp} from 'vue'
import {createPinia} from 'pinia'
import '../styles/style.scss'
import '@toolify/client/src/modules/theme/strategies/dark/dark-theme.scss'
import '@toolify/client/src/modules/theme/strategies/fiord-night/fiord-night-theme.scss'
import '@toolify/client/src/styles/colors.scss'
import App from './App.vue'
import {setupClientRoutes} from './router/setupClientRoutes'
import {setupRouter} from './router/setupRouter'
import {runPostClientSetupTasks} from './runPostClientSetupTasks'
import {setupVuePlugins} from '@toolify/client/src/setup/setupVuePlugins'
import CKEditor from '@ckeditor/ckeditor5-vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {all} from '@awesome.me/kit-06b2eb9994/icons'

library.add(all)

const vueApp = createApp(App)

vueApp.component('FontAwesomeIcon', FontAwesomeIcon)

const pinia = createPinia()
vueApp.use(pinia)

const routes = setupClientRoutes(pinia)
const router = setupRouter(routes)
vueApp.use(router)

vueApp.use(CKEditor)

setupVuePlugins(vueApp)

runPostClientSetupTasks(
  router,
  pinia,
  vueApp,
)

vueApp.mount('#app')
